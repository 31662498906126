import { Ref } from 'vue';
import request from '@/tools/request';

// 统计
export const getStatistics = (loading: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-statistic-dashboard.html',
    loading,
  }) as Promise<any>;
};

// 发货排名
export const getRanking = (params: any, loading: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-statistic-rank.html',
    loading,
    params,
  }) as Promise<any>;
};

// 使用趋势
export const getGraph = (params: any, loading: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-statistic-graph.html',
    loading,
    params,
  }) as Promise<any>;
};
