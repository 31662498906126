
import { defineComponent, onMounted, reactive, ref, toRef } from 'vue';
import '../static/lib/svg.js';
import Table from '@/components/table/Table.vue';
import Charts from '@/components/charts/Charts.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import { getGraph, getStatistics, getRanking } from '../api/statistics';

interface SelectOptions {
  value: number;
  label: string;
}

interface ChartConf {
  loading: boolean;
  x: number[];
  key: number;
  source: any[];
}

export default defineComponent({
  name: 'Statistics',
  setup() {
    const panelLoading = ref(false);
    const topData = ref({
      tracked_order_count: 0,
      tracked_carrier_count: 0,
    });
    const dateList: SelectOptions[] = [
      {
        value: 1,
        label: '今天',
      },
      {
        value: 2,
        label: '昨天',
      },
      {
        value: 3,
        label: '近7天',
      },
      {
        value: 4,
        label: '近30天',
      },
    ];
    const detailsSelect = ref(1);
    const lineSelectIndex = ref(1);

    const changeSel = (type: 'detail' | 'line') => {
      if (type === 'detail') initTable();
      else initTrend();
    };

    const tableConf = reactive({
      loading: false,
      list: [],
    });
    const initTable = async () => {
      const { data } = await getRanking({ type: detailsSelect.value }, toRef(tableConf, 'loading'));
      tableConf.list = data;
    };

    const chartConf = reactive<ChartConf>({
      loading: false,
      x: [],
      key: 0,
      source: [],
    });
    const initTrend = async () => {
      const { data } = await getGraph({ type: lineSelectIndex.value }, toRef(chartConf, 'loading'));
      chartConf.x = data.map(item => item.date);
      chartConf.source = [{ data: data.map(item => item.num), name: '同步物流' }];
      chartConf.key = new Date().getTime();
    };

    const tooltipFormatter = (p: any[]) => {
      if (!p || !p.length) return '';
      let text = p[0].axisValue;
      let ret = `<span style="font-size: 12px; color: #999999; margin-bottom: 8px;">时间 : ${text}</span>`;
      const items = p.map(item => {
        return `
          <div style="margin-top: 4px; font-size: 14px;">
            ${item.marker}
            ${item.seriesName}
            <span style="font-weight: bold;">${item.value}</span>
          </div>
        `;
      });

      ret += items.join('');

      return ret;
    };

    const initStatistics = async () => {
      const { data } = await getStatistics(panelLoading);
      topData.value = data;
    };

    onMounted(() => {
      initStatistics();
      initTrend();
      initTable();
    });

    return {
      panelLoading,
      topData,
      dateList,
      detailsSelect,
      lineSelectIndex,
      changeSel,
      tableConf,
      chartConf,
      tooltipFormatter,
    };
  },
  components: { Table, Charts, Skeleton },
});
