
import { defineComponent, PropType } from 'vue';

type Type = 'panel' | 'form' | 'small-text' | 'text' | 'card';

export default defineComponent({
  name: 'Skeleton',
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
    },
    type: {
      type: String as PropType<Type>,
      default: 'panel',
    },
    count: {
      type: Number as PropType<number>,
    },
    direction: {
      type: String as PropType<'column' | 'row'>,
    },
  },
  setup() {},
});
